import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  error: {
    height: 'calc(100vh - 400px)',
    width: '80%',
    justifyContent: 'center'
  },

  green: {
    color: theme.neonGreen
  },

  title: {
    fontSize: 36,
    lineHeight: 1.08,
    fontWeight: 500,
    letterSpacing: 'normal',
    textAlign: 'center',
    margin: [15, 0],
    [theme.media.mobile]: {
      margin: [10, 0]
    }
  },

  subTitle: {
    fontSize: 20,
    lineHeight: 0.95,
    fontWeight: 400,
    letterSpacing: 'normal',
    textAlign: 'center',
    marginTop: 0
  },

  errorSubtitle: {
    fontSize: 20,
    lineHeight: 1.5,
    fontWeight: 400,
    letterSpacing: 'normal',
    textAlign: 'center',
    marginTop: 0,
    width: '80%'
  },

  buttons: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%'
  },

  button: {
    width: 370
  },

  plans: {
    display: 'flex',
    [theme.media.tablet]: {
      width: '70%',
      justifyContent: 'space-evenly',
      flexDirection: 'column'
    },
    [theme.media.desktop]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      width: '80%'
    }
  },

  buttonContainer: {
    paddingBottom: 30,
    maxWidth: 320,
    width: '100%',
    minWidth: 120
  },

  divider: {
    width: '20%',
    height: 1,
    margin: [5, 0, 15],
    backgroundColor: theme.field,
    minWidth: 320
  },

  priceChange: {
    width: '20%',
    textAlign: 'justify',
    minWidth: 320
  },

  qaLinkContainer: {
    width: '100%',
    textAlign: 'center'
  },

  qaLink: {
    color: theme.neonGreen,
    textDecoration: 'underline'
  },

  copyMessage: {
    textAlign: 'center',
    color: theme.neonGreen
  }
}))

export default useStyles
