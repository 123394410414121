import { usePlanChangeFlag } from './usePlanChange'
import { planType } from '../modules/shared/subscription-constants'

const usePlanStaticDetails = (plan) => {
  const getPlanDetails = usePlanChangeFlag()

  if (plan === planType.STANDARD) {
    return getPlanDetails?.standard.staticDetail
  }

  if (plan === planType.BASIC) {
    return getPlanDetails?.basic.staticDetail
  }

  return getPlanDetails?.annual.staticDetail
}

export { usePlanStaticDetails }
