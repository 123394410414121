import React, { useState } from 'react'
import classNames from 'classnames'
import { usePriceChangeAlertFlag } from '../../../../hooks/usePriceChangeAlert'
import Modal from '../../../../components/modal'
import styles from './temp-price-alert.css'

/**
 * temp price change alert which display the same service msg,
 * when user enter the step2 price table in sign up journey.
 */
const TempPriceAlert = () => {
  const headerText = `Our plans are changing`
  const msgText = `
    On 11 January 2024 Neon's Standard Plan is increasing to $19.99 per month, and Annual Plan to $199.99 per year. Plus we're introducing some ads to Neon. Read more at https://www.neontv.co.nz/changes-2024
    `
  const showPriceChangeAlert = usePriceChangeAlertFlag()
  const [isModalClosed, setModalClosed] = useState(false)
  const closeModal = () => {
    setModalClosed(true)
  }

  if (!showPriceChangeAlert || isModalClosed) {
    return null
  }
  return (
    <Modal>
      <div className={styles.contentBox}>
        <div className={styles.contentFrame}>
          <h3 className={styles.header}>{headerText}</h3>
          <div className={styles.text}>{msgText}</div>
          <div className={styles.buttonGroup}>
            <button
              onClick={closeModal}
              className={classNames(styles.button, styles.closeButton)}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default TempPriceAlert
