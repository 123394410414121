
import getCapitalizedText from './getCapitalizedText'

const getDropdownValue = (value) => {
  return {
    label: (value && Number(value)) ? value : getCapitalizedText(value),
    value
  }
}

export default getDropdownValue
