import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,

    [theme.media.tablet]: {
      padding: [17, 50]
    }
  },

  logo: {
    width: 84,
    height: 32,

    [theme.media.tablet]: {
      width: 132,
      height: 38
    }
  },

  login: {
    color: theme.neonGreen,
    fontSize: 16,
    lineHeight: 0.95,
    paddingBottom: 2,
    borderBottom: '1px solid transparent',

    '&:hover': {
      borderBottom: `1px solid ${theme.neonGreen}`
    },

    [theme.media.tablet]: {
      fontSize: 20
    }
  }
}))
