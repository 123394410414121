import React from 'react'
import { useRouteMatch, NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { serverErrors } from '../../shared/subscription-constants'
import { clearSignupState } from '../lib/signup-state'

import useStyles from './screen.styles'

const Error = () => {
  const styles = useStyles()
  const { params } = useRouteMatch()
  const errorMessage = serverErrors[params.errorCode] || serverErrors.DEFAULT

  return (
    <div className={classNames(styles.container, styles.error)}>
      <h1 className={styles.title}>Oops, something&apos;s gone wrong</h1>
      {errorMessage && <h2 className={styles.errorSubtitle}>{errorMessage}</h2>}

      <div className={styles.buttons}>
        <NavLink
          to="/"
          onClick={clearSignupState}
          className={classNames(styles.primaryButton, styles.button)}
        >
          Neon Home
        </NavLink>
      </div>
    </div>
  )
}

export default Error
