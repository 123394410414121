import { useQuery } from '@apollo/react-hooks'
import CONFIG_QUERY from '../../graphql/queries/config.gql'
import getDeviceInfo from '../lib/device-info'

/**
 * Config query to get all values
 */
export const useConfigQuery = () => {
  const deviceInfo = getDeviceInfo()
  const { data, loading, error } = useQuery(CONFIG_QUERY, {
    variables: {
      input: {
        deviceInfo: {
          ...deviceInfo,
          platform: 'Web'
        }
      }
    },
    notifyOnNetworkStatusChange: true
  })

  return {
    configValues: data && data.config.values,
    loading,
    error
  }
}
