import React from 'react'
import { useLocation } from 'react-router-dom'

import Header from './components/header'
import { useDarkTheme } from './hooks/use-dark-theme'
import AsyncNavigationFooter from '../../containers/navigation/async-navigation-footer'
import UseNotFoundProvider from '../../components/not-found/hooks/use-not-found-context'
import { useStyles } from './layout.styles'

const Layout = ({ children }) => {
  useDarkTheme()
  const styles = useStyles()
  const location = useLocation()

  return (
    <UseNotFoundProvider>
      <div className={styles.layout}>
        <Header />

        <div className={styles.content}>
          {children}
        </div>

        <AsyncNavigationFooter location={location} className={styles.footer} />
      </div>
    </UseNotFoundProvider>
  )
}

export default Layout
