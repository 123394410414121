import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  layout: {
    color: theme.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh'
  },

  content: {
    flex: 1
  },

  footer: {
    padding: '0 2.25%',
    height: '2em',
    lineHeight: '2em'
  }
}))
