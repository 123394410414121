import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useStyles } from './progress-dots.styles'

/**
 * Renders progress information to inform the user
 * how far they are through the sign up flow.
 *
 * @param {{ number, number }} props
 */
const ProgressDots = ({ steps, currentStep }) => {
  const styles = useStyles()
  const dots = new Array(steps).fill(undefined).map((_, idx) => `DOT$${idx}`)
  const activeDot = currentStep - 1
  const label = `Step ${currentStep} of ${steps}`

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>

      <div className={styles.track}>
        {dots.map((dot, idx) => (
          <div key={dot} className={styles.step}>
            <div
              className={classNames(styles.dot, {
                [styles.active]: idx === activeDot
              })}
            />

            {idx < dots.length - 1 && <div className={styles.dash} />}
          </div>
        ))}
      </div>
    </div>
  )
}

ProgressDots.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired
}

export default ProgressDots
