import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { signupImage, links, signupSequence } from '../../shared/subscription-constants'
import { clearSignupState } from '../lib/signup-state'
import { signup } from '../../../lib/analytics/events'
import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS } from '../../../storage-keys'

import useStyles from './screen.styles'
import { useCommonStyles } from '../lib/common.styles'

const Abandon = () => {
  const styles = useStyles()
  const commonStyles = useCommonStyles()
  const location = useLocation()
  const mapState = useSelector(state => state)
  const { session } = mapState

  const onOfferRejected = () => {
    // Remove voucher related subscription when user adandon signup
    sessionStorage.removeItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)

    const userId = session && session.decodedJwt.sub
    signup.completeWithoutSubscription(userId)
    clearSignupState()
  }

  // state in location for detecting sign up flow
  const signupState = { signup: signupSequence.abandon }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Leaving so soon?</h1>

      <div className={commonStyles.container}>
        <p className={commonStyles.copy}>
          Your account is created but you&apos;ll need to add payment details to
          finish adding a subscription.
        </p>

        <img src={signupImage} alt="Neon Plan" className={commonStyles.image} />

        <div className={commonStyles.buttons}>
          <NavLink
            to={{ ...location, pathname: links.payment, state: signupState }}
            className={commonStyles.primaryButton}
          >
            Finish Sign Up
          </NavLink>

          <NavLink
            to="/content-onboarding/profile-selection"
            onClick={onOfferRejected}
            className={commonStyles.secondaryButton}
          >
            No thanks
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Abandon
