import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useStyles } from './create-account-form.styles'

/**
 * Renders a checkbox control with the supplied properties.
 *
 * @param {{ string, boolean, string, ...inputProps }} props
 */
const Checkbox = ({
  label, error, className, ...inputProps
}) => {
  const styles = useStyles()

  return (
    <div className={classNames(styles.formGroup, className)}>
      <label className={classNames(styles.label, styles.checkboxLabel)}>
        <input {...inputProps} type="checkbox" className={styles.checkbox} />

        <span
          className={classNames(styles.checkboxIndicator, {
            [styles.checked]: inputProps.checked
          })}
          data-e2e={`checkbox-indicator-name-${inputProps.name || ''}`}
        />

        {label}
      </label>

      <p
        className={classNames(styles.fieldError, {
          [styles.visible]: Boolean(error)
        })}
      >
        {error}
      </p>
    </div>
  )
}

Checkbox.propTypes = {
  label: PropTypes.element.isRequired,
  error: PropTypes.string,
  className: PropTypes.string
}

export default Checkbox
