import React, { useEffect } from 'react'

import Loader from '../components/loader'
import PaymentForm from '../components/payment-form'
import ProgressDots from '../components/progress-dots'

import { signup } from '../../../lib/analytics/events'
import { useSubscriptionPayment } from '../../shared/hooks/use-subscription-payment'

import useStyles from './screen.styles'
import { signupSequence } from '../../shared/subscription-constants'

const Payment = () => {
  const { loading, plan, ...paymentFormProps } = useSubscriptionPayment()
  const styles = useStyles()

  useEffect(() => {
    signup.subscriptionPaymentSeen(plan)
  }, [])

  return (
    <>
      <ProgressDots steps={3} currentStep={signupSequence.payment} />

      <div className={styles.container}>
        <h1 className={styles.title}>Add payment details</h1>

        {!loading && <PaymentForm {...paymentFormProps} />}
      </div>

      <Loader visible={loading} />
    </>
  )
}

export default Payment
