import qs from 'query-string'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useApiClient } from './use-api-client'
import { clearSignupState } from '../../signup/lib/signup-state'
import { links, planType, signupSequence } from '../subscription-constants'
import { useRedirectSignUp } from './use-redirect-sign-up'
import { usePlanStaticDetails } from '../../../hooks/usePlanStaticDetails'

/**
 * Responsible for fetching and providing data to the
 * subscription confirmation screen during sign up flow.
 */
export const useSubscriptionConfirmation = () => {
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState([])
  const { checkLocationToRedirect } = useRedirectSignUp(signupSequence.confirmation)

  const history = useHistory()
  const { search } = useLocation()
  const {
    refreshUserAccount,
    getSubscriptionConfirmationDetails
  } = useApiClient()

  const {
    voucher,
    orderId,
    plan
  } = qs.parse(search)
  const isTvodPlan = plan === planType.TVOD
  const planStaticDetails = usePlanStaticDetails(plan)

  useEffect(() => {
    checkLocationToRedirect()
  }, [])

  useEffect(() => {
    const getServerData = async () => {
      try {
        setLoading(true)
        await refreshUserAccount()

        if (isTvodPlan) {
          setLoading(false)
          setDetails('')
          return
        }
        const subConfirmationDetails = await getSubscriptionConfirmationDetails(orderId)
        const planDetails = [planStaticDetails, ...subConfirmationDetails]

        setDetails(planDetails)

        setLoading(false)
      } catch (error) {
        history.push(`${links.error}/${error.message}`)

        setLoading(false)
      }
    }

    getServerData()
    clearSignupState()
  }, [orderId, plan, planStaticDetails])

  return {
    plan,
    loading,
    details,
    orderId,
    voucher,
    hasSubscription: Boolean(orderId),
    isTvodPlan
  }
}
