import { useEffect } from 'react'

import { useAuth } from '../../shared/hooks/use-auth'
import { setTheme } from '../../../actions'
import { THEME_OPTIONS } from '../../../constants'

/**
 * Sets the theme to dark
 */
export const useDarkTheme = () => {
  const { dispatch } = useAuth()

  useEffect(() => {
    dispatch(setTheme(THEME_OPTIONS.dark))
  }, [])
}
