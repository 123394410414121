import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useStyles } from './create-account-form.styles'

/**
 * Renders an input field with the supplied props.
 */
const Input = React.forwardRef(({ label, error, ...inputProps }, ref) => {
  const styles = useStyles()

  return (
    <div className={styles.formGroup}>
      <label className={styles.label}>
        {label}

        <input
          {...inputProps}
          ref={ref}
          className={classNames(styles.input, {
            [styles.inputError]: Boolean(error)
          })}
        />
      </label>

      <p
        className={classNames(styles.fieldError, {
          [styles.visible]: Boolean(error)
        })}
      >
        {error}
      </p>
    </div>
  )
})

Input.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string
}

export default Input
