import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { links } from '../../../shared/subscription-constants'
import { useStyles } from './create-account-form.styles'

/**
 * Creates a voucher input field using the
 * specified props.
 */
const VoucherInput = ({ label, error, ...inputProps }) => {
  const styles = useStyles()

  return (
    <div className={styles.formGroup}>
      <label className={styles.label}>
        {label}

        <input
          {...inputProps}
          className={classNames(styles.input, {
            [styles.inputError]: Boolean(error)
          })}
        />
      </label>

      <div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={links.voucherHelp}
          className={styles.link}
        >
          What is this?
        </a>
      </div>

      <p
        className={classNames(styles.fieldError, {
          [styles.visible]: Boolean(error)
        })}
      >
        {error}
      </p>
    </div>
  )
}

VoucherInput.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string
}

export default VoucherInput
