import React from 'react'
import { ThemeProvider } from 'react-jss'

import Layout from './layout'
import Router from './router'
import theme from '../../theme'
import AuthProvider from '../shared/hooks/use-auth'
import ApiClientProvider from '../shared/hooks/use-api-client'

const SignupModule = () => (
  <ThemeProvider theme={theme}>
    <ApiClientProvider>
      <AuthProvider>
        <Layout>
          <Router />
        </Layout>
      </AuthProvider>
    </ApiClientProvider>
  </ThemeProvider>
)

export default SignupModule
