import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { links } from '../../../shared/subscription-constants'
import { useAuth } from '../../../shared/hooks/use-auth'
import { MODALS } from '../../../../constants'
import { getModalLocation } from '../../../../lib/modal'

import NeonLogo from '../../../../../images/neon/neon-logo-hoz-wht-rgb.svg'
import { useStyles } from './header.styles'

/**
 * Renders a re-usable header with a neon logo and optional
 * login button.
 */
const Header = () => {
  const styles = useStyles()
  const location = useLocation()
  const { isAuthenticated } = useAuth()
  const backLink = isAuthenticated ? '/content-onboarding/profile-selection' : '/'

  // Take the user to the confirmation screen if they abandon after
  // creating an account. Before / during account creation, take em to
  // the home page.
  const logoLink =
    location.pathname === links.payment
      ? { ...location, pathname: links.abandon }
      : backLink

  const loginLink = getModalLocation(location, MODALS.qsParams.login)

  return (
    <div className={styles.header}>
      <NavLink to={logoLink}>
        <img src={NeonLogo} alt="Neon Logo" className={styles.logo} />
      </NavLink>

      {!isAuthenticated && (
        <NavLink to={loginLink} className={styles.login}>
          Log in
        </NavLink>
      )}
    </div>
  )
}

export default Header
