import React from 'react'
import {
  Switch, Route, Redirect, useLocation
} from 'react-router-dom'

import { links } from '../shared/subscription-constants'

import CreateAccount from './screens/01-create-accout'
import Payment from './screens/03-payment'
import Confirmation from './screens/04-confirmation'
import Abandon from './screens/05-abandon'
import Error from './screens/error'
import PlanSelectionWithOffer from './screens/02-plan-selection-with-offer'

const Router = () => {
  const location = useLocation()

  return (
    <Switch>
      <Route path={links.createAccount} component={CreateAccount} />
      <Route path={links.planSelection} component={PlanSelectionWithOffer} />
      <Route path={links.payment} component={Payment} />
      <Route path={links.confirmation} component={Confirmation} />
      <Route path={links.abandon} component={Abandon} />
      <Route path={`${links.error}/:errorCode?`} component={Error} />

      <Redirect to={{
        ...location,
        pathname: links.createAccount
      }}
      />
    </Switch>
  )
}

export default Router
