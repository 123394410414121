import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../lib/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  red: {
    color: theme.error
  },

  divider: {
    width: '100%',
    height: 1,
    margin: [5, 0, 15],
    backgroundColor: theme.field
  },

  annualInfo: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5,
    textAlign: 'justify',
    marginBottom: 20
  },

  iframe: {
    width: '100%',
    minHeight: 387,
    border: 'none'
  },

  tvodOnholdInfo: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5,
    margin: [0, 15, 20, 15]
  },

  copyMessage: {
    textAlign: 'center',
    color: theme.neonGreen
  }
}))
