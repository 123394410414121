import React from 'react'
import PropTypes from 'prop-types'
import FullScreenLoadingComponent from '../../../../components/loading/fullscreen-loader'

/**
 * Wrapper for the FullScreenLoadingComponent to abstract the slightly
 * confusing API
 *
 * @param {{ boolean }} props
 */
const Loader = ({ visible }) => (
  <FullScreenLoadingComponent pastDelay={visible} />
)

Loader.propTypes = {
  visible: PropTypes.bool
}

Loader.defaultProps = {
  visible: true
}

export default Loader
