import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  label: {
    color: '#9e9e9e',
    margin: [0, 0, 10]
  },

  track: {
    display: 'flex',
    overflowX: 'auto',
    maxWidth: 'calc(100% - 40px)',
    padding: [0, 20, 10]
  },

  step: {
    display: 'flex',
    alignItems: 'center'
  },

  dot: {
    display: 'block',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#565656'
  },

  active: {
    backgroundColor: '#9e9e9e'
  },

  dash: {
    display: 'block',
    width: 26,
    height: 1,
    backgroundColor: '#565656'
  }
}))
