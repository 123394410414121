import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { path } from 'ramda'
import { planType } from '../subscription-constants'

/**
 * Prevent user access randomly access sign up flow
 * @param currentState current location state in sign up flow
 * @param isFromAbandon if enter sign up journey from abandon page or banner
 */
export const useRedirectSignUp = (currentState, isFromAbandon = false) => {
  const location = useLocation()
  const history = useHistory()

  const { plan } = qs.parse(location.search)
  const planList = Object.values(planType)
  const isInPlanList = planList.indexOf(plan) > -1

  const previousState = path(['state', 'signup'])(location)
  const isOnCorrectFlow = previousState && currentState - previousState === 1

  const checkLocationToRedirect = () => {
    const newState = { signup: currentState }
    let { state: signupState } = location

    if (isOnCorrectFlow || isFromAbandon) {
      if (!signupState) {
        signupState = {}
      }
      Object.assign(signupState, newState)
      return
    }
    if (!plan || !isInPlanList) history.push('/')
  }

  return {
    checkLocationToRedirect,
    isInSignupFlow: isOnCorrectFlow
  }
}
